<template lang="pug">
h2 FUND!
</template>

<script>
export default {
	name: 'Fund'
}
</script>

<style lang="scss">
</style>